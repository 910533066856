import React, { FC, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { makeStyles, Theme } from '@material-ui/core/styles'
import Toolbar from '@material-ui/core/Toolbar'
import logo from '../../assets/images/logo.svg'
import { MainMenu } from '../MainMenu'
// import { LanguageSwitch } from '../LanguageSwitch'
import { Login } from '../Login'
import { Hidden, Typography } from '@material-ui/core'
import { Account } from '../Account'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { useHistory } from 'react-router'
import { PATH_ROOT } from '../../constants'

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    boxShadow: '0px 0.5px 4px rgba(0, 0, 0, 0.15)',
  },
  toolBarWrapper: {
    backgroundColor: '#fff',
    position: 'fixed',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-around',
    zIndex: theme.zIndex.drawer + 1,
    top: '0px',
  },
  customizeToolbar: {
    width: '100%',
    minHeight: 64,
    padding: theme.spacing(0, 4),
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  toolbarScrolled: {
    zIndex: 1000,
    borderBottom: '1px solid rgba(0, 0, 0, 0.03)',
  },
  logo: {
    display: 'flex',
    flexShrink: 0,
    width: 155,
    height: 46,
    marginRight: 20,
  },
  logoImg: {
    width: '100%',
    height: '100%',
  },
  nav: {
    display: 'flex',
    flexDirection: 'row',
  },
  navItem: {
    whiteSpace: 'nowrap',
  },
  mobileHeaderTop: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
  },
  point: {
    cursor: 'pointer',
  },
  login: {
    display: 'block',
    lineHeight: '24px',
    marginTop: '15px',
    fontFamily: '"Helvetica Neue", "Arial", sans-serif',
    fontSize: '0.875rem',
    textDecoration: 'none',
    color: 'black',
    whiteSpace: 'normal',
    fontWeight: 600,
    paddingLeft: 32,
    '&:hover': {
      opacity: 1,
      textDecoration: 'underline',
    },
  },
  banner: {
    width: '100%',
    maxWidth: '1000px',
    backgroundColor: '#000',
    displayflex: 'flex',
    position: 'relative',
    padding: theme.spacing(2, 4),
    color: '#eee',
    '& a': {
      color: '#eee',
      fontWeight: 'normal',
    },
    marginTop: theme.spacing(12),
    marginBottom: theme.spacing(-8),
  },
  bannerMark: {
    float: 'left',
    width: '24px',
    textAlign: 'center',
    marginRight: '15px',
    borderRadius: '100%',
    border: '2px solid white',
  },
  bannerText: {
    width: 'calc(100% - 60px)',
  },
  closeButton: {
    fontWeight: 'bold',
    width: '24px',
    float: 'right',
    order: 0,
    backgroundColor: 'black',
    color: 'white',
    border: '2px solid white',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#666',
    },
    position: 'absolute',
    right: '16px',
    top: '16px',
  },
}))

export const Header: FC = () => {
  const [scrolled, setScrolled] = useState(false)
  const classes = useStyles()
  const history = useHistory()
  const { t } = useTranslation()
  const [isBannerVisible, setIsBannerVisible] = useState(false)

  const routerHomeHandler = useCallback(
    (route) => {
      history.push(route)
    },
    [history],
  )

  const listenScrollEvent = (_e: unknown) => {
    if (window.scrollY > 10) {
      setScrolled(true)
    } else {
      setScrolled(false)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', listenScrollEvent)
  })

  useEffect(() => {
    // Check if bannerDismissed cookie is set
    const isDismissed = document.cookie
      .split(';')
      .some((cookie) => cookie.trim().startsWith('bannerDismissed='))
    if (!isDismissed) {
      setIsBannerVisible(true)
    }
  }, [])

  const handleClose = () => {
    // Set a cookie to remember that the banner has been dismissed
    // Here we're setting it to expire in one year (31536000 seconds)
    document.cookie = `bannerDismissed=true; path=/; max-age=31536000`
    setIsBannerVisible(false)
  }

  const isBot = () => {
    // check if it's a bot from the user agent token
    const botPattern =
      '(googlebot/|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Google-InspectionTool|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)'
    const regex = new RegExp(botPattern, 'i')
    const userAgent = navigator.userAgent
    if (regex.test(userAgent)) {
      return true
    }
    return false
  }

  const bot = isBot()

  return (
    <Grid container justify={'center'}>
      <Box className={`${classes.toolBarWrapper} ${scrolled ? classes.toolbarScrolled : ''}`}>
        <Grid item xs={11} container justify={'center'}>
          <Toolbar className={classes.customizeToolbar}>
            <Box className={classes.logo}>
              <img
                src={logo}
                alt={'Klarpay AG'}
                className={classes.point}
                onClick={() => routerHomeHandler(PATH_ROOT)}
              />
            </Box>

            <MainMenu />

            <Hidden smDown>
              <Box display="flex" alignItems="center">
                {/* <LanguageSwitch /> */}
                <Login />
                <Account />
              </Box>
            </Hidden>
          </Toolbar>
        </Grid>
      </Box>
    </Grid>
  )
}
