const accountCurrencies = {
  type: 'account_currencies',
  sticky_note: 'More currencies available upon request.<br/>*Restrictions may apply.',
  visible_columns: ['currency', 'region', 'clearing_system'],
  hidden_columns: [],
  column_names: {
    currency: '16',
    region: '04',
    clearing_system: '03',
  },
  data: [
    {
      currency: 'EUR',
      region: 'Worldwide',
      clearing_system: 'SEPA, TARGET2, SWIFT',
    },
    {
      currency: 'USD',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'GBP',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'CHF',
      region: 'Worldwide',
      clearing_system: 'SIC, SWIFT',
    },
    {
      currency: 'AED',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'AUD',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'CAD',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'CZK',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'DKK',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'HKD',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'HUF',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'JPY',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'MXN',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'NOK',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'PLN',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'SEK',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'SGD',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'TRY',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
    {
      currency: 'ZAR',
      region: 'Worldwide',
      clearing_system: 'SWIFT',
    },
  ],
}

const exportedObject = accountCurrencies

export default exportedObject
